<template>
  <b-modal
    id="modalInformacaoValor"
    ref="modalInformacaoValor"
    size="lg"
    hide-footer
    no-close-on-backdrop
    fullscreen
  >
    <template #modal-title>
      <div>
        <h4>
          <font-awesome-icon
            icon="fa-solid fa-circle-dollar-to-slot"
            style="font-size: 25px;"
          />
          Valores por rotina
        </h4>
      </div>
    </template>
    <div>
      <b-table
        :items="rows"
        :fields="columns"
        class="spreadsheet-table"
      >
        <template #cell(rotina)="data">
          <span v-html="formatarRotina(data.item.rotina)" />
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<style scoped>
.modal-allow-overflow .modal-content {
  overflow: visible;
}

.spreadsheet-table,
.spreadsheet-table th,
.spreadsheet-table td {
  border: 1px solid #ddd;
  padding: 10px;
  border-collapse: collapse;
  cursor: pointer;
}

.spreadsheet-table {
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.spreadsheet-table tr:hover {
  transform: scale(1.03);
  transition: transform 0.2s ease-in-out;
  z-index: 1000;
}
</style>

<script>
export default {
  props: {
    api: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        { key: 'rotina', label: 'Rotina' },
        { key: 'creditos', label: 'Créditos por Rotina' },
      ],
      rows: [],
      // eslint-disable-next-line global-require
      logoReceitaFederal: require('@/assets/images/logo/receita_federal.png'),
      // eslint-disable-next-line global-require
      logoSimplesNacional: require('@/assets/images/logo/simples_nacional.png'),
    }
  },
  mounted() {
    this.montarRow()
  },
  methods: {
    abrirModal() {
      this.$refs.modalInformacaoValor.show()
    },
    montarRow() {
      this.api.getCreditoRotina()
        .then(payload => {
          const linhas = payload.data.map(item => ({
            rotina: item.tipoRotina,
            creditos: item.quantidade,
          }))
          this.rows = linhas.flatMap(item => {
            if (item.rotina === 'DAS') {
              return [
                { ...item, rotina: 'DAS mensal' },
                { ...item, rotina: 'DAS recálculo' },
              ]
            }
            return [item]
          })
        })
        .catch(error => {
          this.$error('Erro ao carregar os dados:', error)
        })
    },
    formatarRotina(value) {
      return `<img src="${value.includes('DAS') ? this.logoSimplesNacional : this.logoReceitaFederal}" style="width: 30px; height: 30px; margin-right: 10px;" /> ${value}`
    },
  },
}
</script>
