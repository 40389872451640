<template>
  <cadastro-base
    :salvar="adicionarCreditos"
    :cancelar="cancelar"
  >
    <template slot="conteudo">
      <div slot="modal-title">
        <h5>Instruções de recarga
          <feather-icon
            v-b-tooltip.hover
            :title="mensagemInfo"
            icon="InfoIcon"
            size="15"
          />
        </h5>
        <fieldset class="font-weight-bold text-muted">
          Selecione a quantidade de créditos que deseja adicionar a sua base. O crédito adquirido será adicionado a sua carteira e o valor será informado ao financeiro.
        </fieldset>
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </div>
      <b-row style="margin-top: 1%;">
        <b-btn
          variant="none"
          class="btn btn-none ml-1"
          :class="showAquisicaoCredito ? 'btn-light' : 'btn-primary'"
          @click="abrirAquisicaoCredito()"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
          Recarregar saldo
        </b-btn>
        <b-btn
          variant="none"
          class="btn btn-secondary btn-none ml-1"
          @click="openModalInformacaoValor()"
        >
          <font-awesome-icon icon="fa-solid fa-tags" /> Valor
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          variant="none"
          title="Histórico de aquisição de créditos"
          class="btn-none icone-acao ml-1"
          @click="openModalHistoricoCompra()"
        >
          <font-awesome-icon
            icon="fa-sharp fa-solid fa-list"
            class="text-primary"
          />
        </b-btn>
      </b-row>
      <Transition
        :duration="550"
        name="nested"
        style="margin-block-end: 5rem"
      >
        <div v-if="showAquisicaoCredito">
          <div class="inner">
            <h4>
              <font-awesome-icon
                icon="fa-solid fa-circle-dollar-to-slot"
                style="font-size: 30px;"
                class="text-primary"
              />
              Inclusão de créditos
            </h4>
            <b-form-group label-for="aquisicao-credito">
              <h5 style="margin-top: 10px;">
                <span style="margin-right: 1rem">Quantidade</span>
                <b-form-input
                  v-model="form.quantidade"
                  type="number"
                  min="0"
                  step="1"
                  no-wheel="false"
                  style="font-size: 20px; max-width: 8rem; display: inline;"
                  @keydown="verificarTecla"
                />
                <span style="margin-left: 1rem;">Créditos = R$ {{ (form.quantidade * valorCredito).toFixed(2).replace('.', ',') }}</span>
              </h5>
            </b-form-group>
          </div>
        </div>
      </Transition>
      <div style="margin-top: 1rem;">
        <!-- <span>
          O Saldo adicionado ficará vigente até o último dia do mês e poderá ser utilizado nas rotinas.
        </span> -->
        <fieldset class="font-weight-bold text-muted">
          O Saldo adicionado ficará vigente até o último dia do mês e poderá ser utilizado nas seguintes rotinas: Situação fiscal, Caixa postal (leitura de mensagens não lidas), DCTFWeb e DAS.
        </fieldset>
        <fieldset class="font-weight-bold text-muted">
          Custo por rotina: <span style="margin-right: 2rem; margin-left: 1rem;">Situação fiscal = 1 crédito</span>
          <span style="margin-right: 2rem;">Caixa postal = 1 crédito por leitura</span>
          <span style="margin-right: 2rem;">DCTFWeb = 2 créditos</span>
          <span>DAS = 2 créditos</span>
        </fieldset>
      </div>
      <informacao-valor
        ref="modalInformacaoValor"
        :api="apiAquisicaoCredito"
      />
      <historico-compra
        :ref="`modalHistoricoCompra`"
        title="Histórico compra"
        :api="apiAquisicaoCredito"
      />
      <loading
        :show-cancel-button="false"
        :is-loading="isLoading"
        titulo-carregamento="Aguarde!"
      />
    </template>
  </cadastro-base>
</template>

<style scoped>
.outer, .inner {
  padding: 30px;
  min-height: 100px;
}
.nested-enter-active, .nested-leave-active {
  transition: all 0.3s ease-in-out;
}
.nested-leave-active {
  transition-delay: 0.25s;
}
.nested-enter-from, .nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.nested-enter-active .inner, .nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
.nested-enter-from .inner, .nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
</style>

<script>
import CadastroBase from '@pilar/components/cadastro-base/CadastroBase.vue'
import Vue from 'vue'
import InformacaoValor from './shared/components/informacao-valor/InformacaoValor.vue'
import api from './shared/services/api'

export default {
  components: {
    CadastroBase,
    InformacaoValor,
    HistoricoCompra: () => import('./shared/components/historico-compra/HistoricoCompra.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      mensagemInfo: 'Adicione créditos a sua carteira para ter mais flexibilidade na atualização de suas rotinas...',
      apiAquisicaoCredito: api,
      showAquisicaoCredito: false,
      valorCredito: 0,
      quantidadeDefault: 150,
      form: {
        quantidade: 0,
        autenticacao: {
          senha: '@Monitor#2024',
          login: '',
        },
      },
      isLoading: false,
    }
  },
  mounted() {
    this.getValorCredito()
    this.ultimaQuantidade()
  },
  methods: {
    cancelar() {
      this.$router.push({ name: 'dashboard-analitico' })
    },
    getValorCredito() {
      api.getValorCredito().then(payload => {
        this.valorCredito = payload.data
      })
    },
    ultimaQuantidade() {
      api.getUltimaQuantidade().then(payload => {
        this.ultimaQuantidade = payload.data
        this.quantidadeDefault = payload.data === undefined || payload.data === 0 ? this.quantidadeDefault : payload.data
      })
    },
    abrirAquisicaoCredito() {
      this.showAquisicaoCredito = !this.showAquisicaoCredito
      this.form.quantidade = this.quantidadeDefault
    },
    openModalInformacaoValor() {
      this.$refs.modalInformacaoValor.abrirModal()
    },
    openModalHistoricoCompra() {
      this.$refs.modalHistoricoCompra.show()
    },
    verificarTecla(event) {
      const teclasInvalidas = ['.', 'e', ',']
      if (teclasInvalidas.includes(event.key)) {
        event.preventDefault()
      }
    },
    getUltimoDiaDoMes() {
      const today = new Date()
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      return lastDayOfMonth.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
    },
    adicionarCreditos() {
      if (this.showAquisicaoCredito) {
        if (this.form.quantidade > 0) {
          Vue.swal({
            title: '<div class="text-danger">Atenção!</div>',
            html: `
              Prezado usuário, Informamos que a compra
              de <b>${this.form.quantidade}</b> créditos por <b>R$ ${(this.form.quantidade * this.valorCredito).toFixed(2).replace('.', ',')}</b> tem validade até 
              o último dia deste mês <b>${this.getUltimoDiaDoMes()}</b>.
              </br>Após essa data, os
              créditos não utilizados expirarão automaticamente, e o saldo restante será perdido. </br>
              O valor será cobrado na sua próxima fatura.</br>
              Aproveite para utilizar seus créditos dentro do prazo e maximize seus benefícios! </br></br>

              Para confirmar sua compra insira sua senha e confirme!</br></br>

              <input type="password" title="Senha" id="input-senha" class="swal2-input" placeholder="Digite sua senha" style="height: 40px; width: 70%;"/>
            `,
            icon: 'warning',
            iconColor: '#ea5455',
            width: '25%',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            customClass: {
              popup: 'swal-m',
              confirmButton: 'btn btn-outline-success mr-50',
              cancelButton: 'btn btn-outline-danger mr-50',
            },
            footer: '<div class="text-danger">Atenciosamente, Equipe Monitor Contábil</div>',
            buttonsStyling: false,
          })
            .then(result => {
              if (result.isConfirmed) {
                this.isLoading = true
                this.form.autenticacao.senha = document.getElementById('input-senha').value
                this.form.autenticacao.login = this.userData.email

                api.adicionarCredito(this.form)
                  .then(() => {
                    this.$sucesso('Crédito adicionado com sucesso.')
                  })
                  .catch(err => {
                    this.$erro(err.response.data.message)
                  })
                  .finally(() => {
                    this.isLoading = false
                  })
                this.form.quantidade = this.quantidadeDefault
                this.showAquisicaoCredito = false
              }
            })
            .catch(err => {
              this.$erro(err.response.data.message)
            })
        } else {
          this.$erro('Tem que contenter mais de um credito')
        }
      }
    },
  },
}
</script>
