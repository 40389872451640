import axios from '@axios'

const PREFIX = '/aquisicao-creditos'

export default {

  adicionarCredito(aquisicaoCredito) {
    return axios.post(`${PREFIX}/creditos`, aquisicaoCredito)
  },

  getHistoricoCompra() {
    return axios.get(`${PREFIX}/historicos/compras`)
  },

  getValorCredito() {
    return axios.get(`${PREFIX}/creditos/valor`)
  },

  getCreditoRotina() {
    return axios.get(`${PREFIX}/rotinas/creditos`)
  },

  getUltimaQuantidade() {
    return axios.get(`${PREFIX}/historico/ultima-quantidade`)
  },

}
